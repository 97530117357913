import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  // RouterView,
} from "vue-router";

// import { supabase } from '@/utils/supabase';
import { useAuth } from '@/utils/supabase_auth';
const { getSession } = useAuth();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layouts/Layout.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/anonycap",
        name: "anonycap",
        component: () => import("@/views/SCapView.vue"),
      },
      {
        path: "/gibreporter",
        name: "gibreporter",
        component: () => import("@/views/GibReporter.vue"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/views/About.vue"),
      },
      {
        path: "/privacy",
        name: "privacy",
        component: () => import("@/views/Privacy.vue"),
      },      
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
      },
      // {
      //   path: "/register",
      //   name: "register",
      //   component: () => import("@/views/Register.vue"),
      // },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/Account.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-tasks",
        name: "manage-tasks",
        component: () => import("@/views/ManageTasksView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-gibreporter-templates",
        name: "manage-gibreporter-templates",
        component: () => import("@/views/ManageGibTemplates.vue"),
        meta: { requiresAuth: true },
      },
      // {
      //   path: "/test",
      //   name: "test",
      //   component: () => import("@/views/Test.vue"),
      //   // meta: { requiresAuth: true },
      // },
    ],
  },
];

const router = createRouter({ 
  history: createWebHistory(), 
  routes 
});

// Add navigation guard
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // const { data: { session } } = await supabase.auth.getSession();
    // const user = await getUser();
    // console.log('user', user);
    const session = await getSession();
    // console.log('session', session);
    if (!session) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
