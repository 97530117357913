<template>
  <div class="antialiased bg-black h-screen">
    <NavBar :menu="menuConfig" />
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavBar from "@/components/NavBar.vue";
import { menuConfig } from "@/config/MainMenuConfig";

export default defineComponent({
  name: "App",
  components: {
    NavBar,
  },
  setup() {
    return { menuConfig };
  },
});
</script>

<style>

body {
  background-color: black;
  color: white; /* Optional: To ensure text is visible on a black background */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #d8dee4;
  background-color: #0d110d;
}

input {
  background-color: d8dee4; /* White background */
  color: rgb(34, 34, 34);           /* Dark text for contrast */
  padding: 3px;           /* Some padding for aesthetics */
  font-size: 16px;        /* Suitable font size */
  border: 2px solid #0d110d; /* Light gray border */
}

.divider {
  border: 0;
  height: 1px;
  background: #a3aca0; /* Change this to your desired color */
  margin: 24px 0; /* Adjust spacing as needed */
}

</style>
