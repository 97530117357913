// src/utils/supabase_auth.js

import { supabase } from '@/utils/supabase';

const hostProtocol = window.location.protocol + '//';
const hostName = window.location.hostname;
const redirectURL = hostProtocol + hostName + '/account';

const signInWithGoogle = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: { redirectTo: redirectURL },
  });
  // console.log(data);
  if (error) console.error('Error signing in with Google:', error);
  return data;
};

// const signInWithGoogleButton = async (response: any) => {
//   const { data, error } = await supabase.auth.signInWithIdToken({
//     provider: 'google',
//     token: response.credential,
//     // options: { redirectTo: redirectURL },
//   })
//   // console.log(data);
//   if (error) console.error('Error signing in with Google:', error);
//   return data;
// };

const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) console.error('Error signing out:', error);
};

const getUser = async () => {
  const { data, error } = await supabase.auth.getUser();
  // console.log('user data', data)
  if (error) console.error('Error getting user:', error)
  return data.user
}

const getSession = async () => {
  const { data, error } = await supabase.auth.getSession()  
  // console.log('session data', data)
  if (error) console.error('Error getting session:', error)
  return data.session
}

export function useAuth() {
  return {
    signInWithGoogle,
    // signInWithGoogleButton,
    signOut,
    getUser,
    getSession,
  };
}
